<style>
.el-icon-info{color:#999; font-size:32px; margin-right:5px;}
.line{display:flex; flex-wrap: nowrap;}
.role{ max-width:150px; }
.subject{font-size:12px; color:#999;}
.value{font-size:12px; color:#666; }
.el-cascader{width:170px;}
.keyword{width:200px;}
.red{color:red;}
.block td{background-color:#e6e6e6 !important}
.btn_location{font-size:20px; color:#099831; padding-right:5px; cursor:pointer;}
.edit_dialog{ height:90vh; margin:5vh auto !important }
.edit_dialog .big-hint{font-size:17px; color:#777; font-weight: bold}
.edit_dialog .el-row:nth-of-type(n+2){margin-top:10px;}
.edit_dialog .label{font-size:15px; color:#909399;}
.edit_dialog .btn-add{display:flex; justify-content: center; align-items: center; font-size:22px; color:#aaa;}
.mini-table td{padding:3px 0 !important;}
.img-bar{display:flex; align-items: stretch; overflow-x: auto; flex-wrap: nowrap;}
.img-box{flex:0 0 auto; position: relative; width:60px; height:60px; margin-right:5px; overflow: hidden; border:solid 1px #ccc; border-radius:5px; cursor:pointer;}
.mask{position: absolute; top:0; right:0; padding:2px 4px; font-size:16px; z-index:2; background:rgba(0,0,0,0.7); text-align: center; color:#fff; border-radius:0 0 0 5px;}
.img{width:100%; height:100%; object-fit: cover; }
.el-image-viewer__wrapper{z-index:2021 !important;}
.flex-grow{flex:1 1 auto; display: flex; align-items: stretch; margin-top: 10px !important;}
.sort-input .el-input__inner{text-align: center;  padding:0;}
</style>
<template>
    <div class="component">
         <div class="title-panel">
            <el-row>
                <el-col :span="7">
                    <i class="el-icon-info"></i>
                    <div class="inline"><div class="label">验证码日志</div><div class="descript">查看验证码发送记录</div></div>
                </el-col>
                <el-col :span="17" class="btn-col">
                    <!-- <el-button type="success" plain icon="el-icon-circle-plus-outline" @click="click_add">添加资讯</el-button> -->
                    <el-button type="primary" plain icon="el-icon-refresh" @click="load_list">刷新数据</el-button>
                </el-col>
            </el-row>
        </div>
        <!-- 筛选条 -->
        <el-row style="display:flex; align-items: center; background: #f9f9f9; padding:5px 5px;">
            <el-select class="role" v-model="status" placeholder="发送状态" clearable size="small">
                <el-option label="未开始" value="0"></el-option>
                <el-option label="发送中" value="1"></el-option>
                <el-option label="已完成" value="2"></el-option>
                <el-option label="失败" value="-1"></el-option>
            </el-select>
            
            <div>&nbsp;</div>
            <el-date-picker v-model="date" type="daterange" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="x" size="small"></el-date-picker>
            <div>&nbsp;</div>
            <el-input style="width:200px" class="keyword" size="small" v-model="key" placeholder="搜索手机号" clearable></el-input> 
            <div style="flex-grow:1">&nbsp;</div>
            <el-pagination background layout="total, prev, pager, next" :page-size="size" :current-page="page" :total="total" @current-change="page_change"></el-pagination>
        </el-row>
        <!-- 筛选条结束 -->
        <div class="table-box" id="table-box">
            <el-table :max-height="table_height" :data="list" :stripe="true" border>
                <el-table-column prop="expire_at" label="发送时间" width="250" align="center" :formatter="format_timestamp"></el-table-column>
                <el-table-column prop="phone" label="接收手机" width="200" align="center"></el-table-column>
                <el-table-column prop="code" label="验证码" width="150" align="center"></el-table-column>
                <el-table-column label="状态" width="200" align="center">
                    <template #default="scope">
                        <template v-if="scope.row.send == -1">
                            <i class="el-icon-warning-outline"></i><span> 失败</span>
                        </template>
                        <template v-else-if="scope.row.send == 0">
                            <i class="el-icon-alarm-clock"></i><span> 未开始</span>
                        </template>
                        <template v-else-if="scope.row.send == 1">
                            <i class="el-icon-video-play"></i><span> 发送中</span>
                        </template>
                        <template v-else>
                            <i class="el-icon-circle-check"></i><span> 已完成</span>
                        </template>
                    </template>
                </el-table-column>
            </el-table>      
        </div>
    </div>
</template>
<script>
import {inject} from 'vue'
import format_timestamp from "../plugins/utils.js"
export default {
    setup:function(){
    	const gd = inject('gd');
    	return {gd};
    },//setup
    data:function(){
        return {
            key_input_delay:null,
            status:'',
            date:null,
            key:'',
            page:1,
            size:10,
            total:0,
            list:[],
            table_height:800,
        }
    },//data end
    mounted:function(){
        this.$nextTick(()=>{ this.table_height = document.getElementById('table-box').offsetHeight -12; });
        this.init();
    },//mounted end
    watch:{
        page:function(){ this.load_list(); },//page
        status:function(){ this.page=1; this.load_list(); },//status
        date:function(){ this.page=1; this.load_list(); },//date
        key:function(){
            clearTimeout(this.key_input_delay);
            this.key_input_delay = setTimeout(()=>{
                this.page=1; this.load_list();
            }, 800);
        },//key
    },
    methods:{
        init: function(){
            this.load_list();
        },//init
        load_list:function(){
            let para = {page:this.page, size:this.size, status:this.status, key:this.key};
            if(this.date != null && this.date.length == 2){ para.date = this.date; }
            this.axios.post("/api/sms/code_list",para).then(ret=>{
                if(!ret.ok){this.$message.error(ret.msg); return;}
                this.total = ret.data.total;
                this.list = ret.data.list;
            });
        },//load_list
        format_timestamp:function(row,col,val){
            return format_timestamp(val, true);
        },//format_timestamp
        page_change:function(page){ this.page = page; },//page_change
    },//methods end
};
</script>